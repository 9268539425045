import { MessageInstance } from 'antd/es/message/interface';
import { Location, NavigateFunction } from 'react-router-dom';

export const routerUtils = {
  navigate: (() => {}) as NavigateFunction,
  location: {} as Location,
};

export const messageUtils = {
  message: {} as MessageInstance,
};
