import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { Button, ButtonProps } from '@mui/material';
import { useMemo, useRef } from 'react';

type TLabel = { label?: string };

type OverlayPanelHelperProps = {
  cancelBtnProps: TLabel & ButtonProps;
  submitBtnProps: TLabel & LoadingButtonProps;
};

/** Use for get button for footer of modal/drawer and return the submit button ref */
const useOverlayPanelHelper = ({
  cancelBtnProps,
  submitBtnProps,
}: OverlayPanelHelperProps) => {
  const { label: submitBtnLabel, ...MUILoadingBtnProps } = submitBtnProps;
  const { label: cancelBtnLabel, ...MUIBtnProps } = cancelBtnProps;

  const submitBtnRef = useRef<HTMLButtonElement | null>(null);

  const overlayPanelFooter = useMemo(() => {
    return (
      <div className="flex justify-end gap-x-2 p-2">
        <Button variant="outlined" {...MUIBtnProps}>
          {cancelBtnLabel ?? 'Hủy'}
        </Button>
        <LoadingButton
          variant="contained"
          {...MUILoadingBtnProps}
          onClick={() => submitBtnRef.current?.click()}
        >
          {submitBtnLabel ?? 'Xác nhận'}
        </LoadingButton>
      </div>
    );
  }, [MUIBtnProps, cancelBtnLabel, MUILoadingBtnProps, submitBtnLabel]);

  return { overlayPanelFooter, submitBtnRef };
};

export default useOverlayPanelHelper;
