import accountingAvatar from 'assets/imgs/accounting.webp';
import adminAvatar from 'assets/imgs/admin.webp';
import contentEditorAvatar from 'assets/imgs/content.webp';
import salesAvatar from 'assets/imgs/sales.webp';
import ticketingAvatar from 'assets/imgs/ticketing.webp';
import {
  isAccountingRoleSelector,
  isAdminRoleSelector,
  isContentEditorRoleSelector,
  isSalesRoleSelector,
  isTicketingRoleSelector,
} from 'features/auth/selector';
import { useAppSelector } from 'lib/stores/hook';
import { useMemo } from 'react';

const useGetUserAvatar = () => {
  const isAdminRole = useAppSelector(isAdminRoleSelector);
  const isContentEditorRole = useAppSelector(isContentEditorRoleSelector);
  const isTicketingRole = useAppSelector(isTicketingRoleSelector);
  const isSalesRole = useAppSelector(isSalesRoleSelector);
  const isAccountingRole = useAppSelector(isAccountingRoleSelector);

  const roleLabel = useMemo(
    () =>
      isAdminRole
        ? 'Admin'
        : isContentEditorRole
          ? 'Content Editor'
          : isTicketingRole
            ? 'Ticketing'
            : isSalesRole
              ? 'Sales'
              : isAccountingRole
                ? 'Kế toán'
                : '',
    [
      isAccountingRole,
      isAdminRole,
      isContentEditorRole,
      isSalesRole,
      isTicketingRole,
    ]
  );

  const userAvatar = useMemo(
    () =>
      isAdminRole
        ? adminAvatar
        : isContentEditorRole
          ? contentEditorAvatar
          : isTicketingRole
            ? ticketingAvatar
            : isSalesRole
              ? salesAvatar
              : isAccountingRole
                ? accountingAvatar
                : '',
    [
      isAccountingRole,
      isAdminRole,
      isContentEditorRole,
      isSalesRole,
      isTicketingRole,
    ]
  );
  return {
    roleLabel,
    userAvatar,
  };
};

export default useGetUserAvatar;
