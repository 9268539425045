import RefreshIcon from '@mui/icons-material/Refresh';
import { TOKEN } from 'features/auth/constants';
import { getAuthToken } from 'features/auth/helpers';
import { useGetBalanceQuery } from 'features/auth/query';
import { currentUserSelector } from 'features/auth/selector';
import { useAppSelector } from 'lib/stores/hook';
import { memo } from 'react';
import { formatDisplayCurrency } from 'utils/dataTypes/string';

const UserDepositAmount = () => {
  const accessToken = getAuthToken(TOKEN.ACCESS_TOKEN);

  const currentUser = useAppSelector(currentUserSelector);

  const { userName, displayName } = currentUser || {};

  const {
    data: getBalanceResponse,
    refetch,
    isFetching,
  } = useGetBalanceQuery(undefined, {
    skip: !accessToken,
    refetchOnMountOrArgChange: true,
  });

  return (
    <div className="flex flex-col gap-1">
      <p className="line-clamp-1 text-sm text-white">
        {displayName ?? userName ?? ''}
      </p>

      <p className="group line-clamp-1 flex items-center gap-x-1 text-sm text-slate-400">
        Số dư:
        <span className="font-semibold text-primary">
          {formatDisplayCurrency(getBalanceResponse?.data?.depositAmount ?? 0)}
        </span>
        {!isFetching && (
          <RefreshIcon
            className="hidden cursor-pointer text-base text-primary group-hover:block"
            onClick={refetch}
          />
        )}
      </p>
    </div>
  );
};

export default memo(UserDepositAmount);
