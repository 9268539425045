import {
  cloneElement,
  isValidElement,
  memo,
  MouseEventHandler,
  PropsWithChildren,
} from 'react';

type ToggleOverlayBtnProps = PropsWithChildren & {
  onClick: MouseEventHandler<HTMLElement>;
};

const ToggleOverlayBtn = ({ onClick, children }: ToggleOverlayBtnProps) =>
  isValidElement(children) ? (
    cloneElement(children, {
      onClick: onClick,
      ...children.props,
    })
  ) : !!children ? (
    <span className="inline-flex items-center" onClick={onClick}>
      {children}
    </span>
  ) : null;

export default memo(ToggleOverlayBtn);
