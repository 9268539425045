import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { authQueryApi } from './query';
import { AuthState } from './types';

const initialState: AuthState = {
  currentUser: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    const { GetCurrentUser } = authQueryApi.endpoints;

    builder
      .addMatcher(
        isAnyOf(GetCurrentUser.matchPending, GetCurrentUser.matchRejected),
        () => {}
      )
      .addMatcher(GetCurrentUser.matchFulfilled, (state, { payload }) => {
        state.currentUser = payload?.data ?? null;
      });
  },
});

export default authSlice.reducer;
