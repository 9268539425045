import { createTheme } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { APIProvider } from '@vis.gl/react-google-maps';
import ConfigProvider from 'antd/es/config-provider';
import vi_VN from 'antd/es/locale/vi_VN';
import message from 'antd/es/message';
import theme from 'antd/es/theme';
import ScrollRestoration from 'components/ScrollRestoration';
import SplashScreen from 'components/SplashScreen';
import DOMPurify from 'dompurify';
import {
  appPrimaryColorSelector,
  darkModeSelector,
} from 'features/appUI/selectors';
import { changeSidebarCollapsedState, setDarkMode } from 'features/appUI/slice';
import { useAppDispatch, useAppSelector } from 'lib/stores/hook';
import { Suspense, useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import AppRouterConfig from 'routes/AppRouterConfig';
import { darkThemeOptions, themeOptions } from 'styles/MuiTheme';
import { setAppFavicon } from 'utils/getAppLogo';
import { messageUtils } from 'utils/utils';

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener noreferrer');
  }
});

const App = () => {
  const dispatch = useAppDispatch();
  const appPrimaryColor = useAppSelector(appPrimaryColorSelector);
  const isDarkMode = useAppSelector(darkModeSelector);

  const MuiTheme = createTheme(isDarkMode ? darkThemeOptions : themeOptions);

  const [messageApi, contextHolder] = message.useMessage();
  messageUtils.message = messageApi;

  useEffect(() => {
    setAppFavicon();
    dispatch(setDarkMode(localStorage.getItem('darkMode') === 'true'));
    dispatch(
      changeSidebarCollapsedState(
        localStorage.getItem('compactSidebar') === 'true'
      )
    );
  }, [dispatch]);

  return (
    <APIProvider apiKey={import.meta.env.VITE_APP_GM_API_KEY}>
      <ThemeProvider theme={MuiTheme}>
        <StyledEngineProvider injectFirst>
          <ConfigProvider
            locale={vi_VN}
            theme={{
              algorithm: isDarkMode
                ? theme.darkAlgorithm
                : theme.defaultAlgorithm,
              token: {
                colorPrimary: appPrimaryColor,
                borderRadius: 5,
              },
            }}
          >
            <Suspense
              fallback={
                <ScrollRestoration>
                  <SplashScreen fullScreen />
                </ScrollRestoration>
              }
            >
              {contextHolder}
              <RouterProvider router={AppRouterConfig} />
            </Suspense>
          </ConfigProvider>
        </StyledEngineProvider>
      </ThemeProvider>
    </APIProvider>
  );
};

export default App;
