export enum MEMBER_REPORT_PAGE_TYPE {
  DEPOSIT = 'deposits',
  TRANSACTION = 'transaction',
  POINTS = 'points',
  PROMOTION_TRANSACTION = 'promotion-transaction',
}

export enum USER_REPORT_PAGE_TYPE {
  CREDIT = 'credit',
  TRANSACTION = 'transaction',
}

export enum GROUP_REPORT_PAGE_TYPE {
  DEPOSIT = 'deposits',
  TRANSACTION = 'transaction',
}

export enum BOOKING_TYPE {
  AIRLINE_TICKETS = 'airline-tickets',
  HOTEL = 'hotel',
  AMUSEMENT_TICKETS = 'amusement-tickets',
  INSURANCE = 'insurance',
  VISA = 'visa',
  COMBO = 'combo',
  TOUR = 'tour',
}

export enum BOOKING_TYPE_INDEX {
  AIRLINE_TICKETS,
  INSURANCE,
  AMUSEMENT_TICKETS,
  HOTEL,
  TOUR,
}

export enum BOOKING_STEP {
  SEARCH = 'search',
  DETAIL = 'detail',
  INFORMATION_INPUT = 'information-input',
  INFORMATION_CHECK = 'information-check',
}

export enum TRANSACTION_REPORT_PAGE_TYPE {
  GATEWAY = 'payment-gateway',
  BANK = 'bank',
}

export enum PROMOTION_CODE_DETAIL_PAGE_TYPE {
  CREATE = 'create',
  UPDATE = 'update',
  USAGE_HISTORY = 'usage-history',
}
