import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'lib/stores';
import { USER_ROLE } from './constants';

export const currentUserSelector = (state: RootState) => state.auth.currentUser;

export const isAdminRoleSelector = createSelector(
  currentUserSelector,
  currentUser => currentUser?.role === USER_ROLE.ADMIN
);

export const isSalesRoleSelector = createSelector(
  currentUserSelector,
  currentUser => currentUser?.role === USER_ROLE.SALES
);

export const isTicketingRoleSelector = createSelector(
  currentUserSelector,
  currentUser => currentUser?.role === USER_ROLE.TICKETING
);

export const isAccountingRoleSelector = createSelector(
  currentUserSelector,
  currentUser => currentUser?.role === USER_ROLE.ACCOUNTING
);

export const isContentEditorRoleSelector = createSelector(
  currentUserSelector,
  currentUser => currentUser?.role === USER_ROLE.CONTENT_EDITOR
);
